<template>
	<div class="grid">
    <div class="col-12">
      <div class="card">
        <h5>Case Browser</h5>
        <DataTable :value="surgeryTableRows" :paginator="true" class="p-datatable-gridlines" :rows="perPage" dataKey="Id" :rowHover="true"
                   v-model:filters="filters1" filterDisplay="menu" :loading="loading1" :filters="filters1" responsiveLayout="scroll"
                   :globalFilterFields="surgeryTableFields" selectionMode="single" @row-select="selectRow" :allowUnsorting="true"
                   sortField="SGS_StartTime" :sortOrder="1">
          <template #header>
            <div class="flex justify-content-between flex-column sm:flex-row">
              <Button type="button" icon="pi pi-filter-slash" label="Clear" class="p-button-outlined mb-2" @click="clearFilter1()"/>
              <span class="p-input-icon-left mb-2">
                <i class="pi pi-search" />
                <InputText v-model="filters1['global'].value" placeholder="Keyword Search" style="width: 100%"/>
              </span>
            </div>
          </template>
          <template #empty>
            No cases found.
          </template>
          <template #loading>
            Loading cases. Please wait.
          </template>
          <Column field="STS_IOH_Under65" header="MAP Duration &lt;65mmHg (mins)" dataType="numeric" style="min-width:12rem" :sortable="true">
            <template #body="{data}">
              {{ Math.round(data.STS_IOH_Under65 / 60) }}
            </template>
            <template #filter="{filterModel}">
              <InputText type="text" v-model="filterModel.value"  class="p-column-filter" mode="numeric" placeholder="Duration"/>
            </template>
          </Column>
          <Column field="STS_Normo_SLU" header="SLU Score" dataType="numeric" style="min-width:12rem" :sortable="true">
            <template #body="{data}">
              {{ data.STS_Normo_SLU }}
            </template>
            <template #filter="{filterModel}">
              <InputText type="text" v-model="filterModel.value"  class="p-column-filter" mode="numeric" placeholder="SLU Score"/>
            </template>
          </Column>
          <Column field="STS_Min_MAP" header="Minimum MAP (mmHg)" dataType="numeric" style="min-width:12rem" :sortable="true">
            <template #body="{data}">
              {{ Math.round(data.STS_Min_MAP) }}
            </template>
            <template #filter="{filterModel}">
              <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Min MAP"/>
            </template>
          </Column>
          <Column field="SPI_SurgeryType" header="Surgery Type" style="min-width:12rem">
            <template #body="{data}">
              {{data.SPI_SurgeryType}}
            </template>
            <template #filter="{filterModel}">
              <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Surgery type"/>
            </template>
          </Column>
          <Column header="Date" filterField="SGS_StartTime" dataType="date" field="SGS_StartTime" :sortable="true">
            <template #body="{data}">
              {{ data.SGS_StartTime.toLocaleString("en-US",
                {
                  day: "numeric",
                  weekday: "short",
                  month: "short",
                  year: "numeric",
                  hour: "2-digit",
                  minute: "2-digit"
                })
              }}
            </template>
            <template #filter="{filterModel}">
              <Calendar v-model="filterModel.value" />
            </template>
          </Column>
          <Column field="SGS_FacilityName" filterField="SGS_FacilityName" header="Facility" dataType="text" style="min-width:12rem">
            <template #body="{data}">
              {{ data.SGS_FacilityName }}
            </template>
            <template #filter="{filterModel}">
              <Dropdown v-model="filterModel.value" :options="facilities" placeholder="Any" class="p-column-filter" :showClear="true">
                <template #value="slotProps">
                  <span :class="'customer-badge status-' + slotProps.value" v-if="slotProps.value">{{slotProps.value}}</span>
                  <span v-else>{{slotProps.placeholder}}</span>
                </template>
                <template #option="slotProps">
                  <span :class="'customer-badge status-' + slotProps.option">{{slotProps.option}}</span>
                </template>
              </Dropdown>
            </template>
          </Column>
          <Column field="SPI_Age" filterField="SPI_Age" header="Age" dataType="numeric" style="min-width:12rem" :sortable="true">
            <template #body="{data}">
              {{ formatAge(data) }}
            </template>
            <template #filter="{filterModel}">
              <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Age"/>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
    <CaseDetailDialog
        :case="selectedCase"
        :timeMAP="timeMAP"
        :timeCO="timeCO"
        :timeSVR="timeSVR"
        :timeHR="timeHR"
        :timeTargets="timeTargets"
        :eventsData="eventsData"
        :timeSLU="timeSLU"
        style="width: 80vw"
        v-model:visible="showCaseDialog"></CaseDetailDialog>
	</div>
</template>

<script>
	import {FilterMatchMode,FilterOperator} from 'primevue/api';
  import {API} from "aws-amplify";
  import 'chartjs-adapter-date-fns';
  import { parseISO } from 'date-fns'
  import CaseDetailDialog from "./CaseDetailDialog";

	export default {
    data() {
			return {
        timeMAP: [],
        timeCO: [],
        timeSVR: [],
        timeHR: [],
        timeTargets: [],
        eventsData: [],
        timeSLU: [],
        selectedCase: null,
        showCaseDialog: false,
        facilities: [],
        perPage: 10,
        surgeryTableRows: [],
        allSurgeryTableRows: [],
        surgeryTableFields: ["STS_IOH_Under65","STS_Min_MAP","SPI_SurgeryType","Bmi","Date","SPI_Age", "Facility"],
        showLoading:false,
				filters1: null,
				loading1: true,
			}
		},
		created() {
			this.initFilters1();
		},
		mounted() {
      API
        .get("TestAPI", "/surgery-sessions", null)
        .then(response => {
          this.allSurgeryTableRows = response.map(row => {
            row.SGS_StartTime = parseISO(row.SGS_StartTime)
            row.SGS_EndTime = parseISO(row.SGS_EndTime)
            row.Bmi = this.computeBmi(row)
            row.STS_IOH_Under55 = Math.round(row.STS_IOH_Under55)
            row.STS_IOH_Under65 = Math.round(row.STS_IOH_Under65)
            row.STS_IOH_Under75 = Math.round(row.STS_IOH_Under75)
            return row
          });
          this.facilities = [...new Set(this.allSurgeryTableRows.map(item => item.SGS_FacilityName))];
          this.surgeryTableRows = this.allSurgeryTableRows;
        })
        .catch(error => {
          console.log(error.response);
          this.loading1 = false;
        })
        .finally(()=>{
          this.loading1 = false;
        });
		},
		methods: {
      formatAge(data) {
        if (data.SPI_AgeMin != null && data.SPI_AgeMax != null)
         return data.SPI_AgeMin + " - " + data.SPI_AgeMax
        else
          return ""
      },
      computeBmi(data) {
        if (data.SPI_Weight == null || data.SPI_Height == null ||
          data.SPI_Weight == 0 || data.SPI_Height == 0)
          return null;
        return Math.round((data.SPI_Weight) / ((data.SPI_Height/100.0) * (data.SPI_Height/100.0)));
      },
			initFilters1() {
				this.filters1 = {
					'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
					'STS_IOH_Under65': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.BETWEEN}]},
          'STS_Normo_SLU': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.BETWEEN}]},
					'STS_Min_MAP': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
					'SPI_SurgeryType': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
          'Bmi': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
					'SGS_StartTime': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.DATE_IS}]},
          'SGS_FacilityName': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
					'SPI_Age': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
				}
			},
			clearFilter1() {
				this.initFilters1();
			},
			formatDate(value) {
        return parseISO(value).toLocaleString()
			},
      selectRow(selectedRow) {
        this.timeMAP = null
        this.timeCO = null
        this.timeHR = null
        this.timeSVR = null
        this.timeTargets = null
        this.timeSLU = null
        this.showCaseDialog = true
        this.selectedCase = selectedRow.data
        API
          .get("TestAPI", `/surgery-session-events/${selectedRow.data.SGS_Id}`,null)
          .then(response => {
            this.eventsData = response
          })
          .catch(error => {
            console.log(error.response);
            this.showLoading = false;
          });
        API
          .get("TestAPI", `/surgery-session-details-minuterolling/${selectedRow.data.SGS_Id}`,null)
          .then(response => {
            this.timeMAP = response.TimeMAP
            this.timeCO = response.TimeCO
            this.timeHR = response.TimeHR
            this.timeSVR = response.TimeSVR
            this.timeTargets = response.TimeTargets
            this.timeSLU = response.TimeNormoSLU
          })
          .catch(error => {
            console.log(error.response);
            this.showLoading = false;
          });
      },
		},
    components: {
      CaseDetailDialog
    }
	}
</script>

<style scoped lang="scss">
@import '../assets/demo/styles/badges.scss';

::v-deep(.p-datatable-frozen-tbody) {
	font-weight: bold;
}

::v-deep(.p-datatable-scrollable .p-frozen-column) {
	font-weight: bold;
}

::v-deep(.p-progressbar) {
	height: .5rem;
	background-color: #D8DADC;

	.p-progressbar-value {
		background-color: #607D8B;
	}
}
</style>
